import shots1 from "../Images/Shots/shots1.png";
import shots2 from "../Images/Shots/shots2.png";
import shots3 from "../Images/Shots/shots3.png";
import shots4 from "../Images/Shots/shots4.png";

export const shots = [
{
    title: "Streaming app",
    shotImage: shots1,
    altText: "shots1",
},
{
    title: "Torch light",
    shotImage: shots2,
    altText: "shots2",
},
{
    title: "Nat geo Honey-Hunter landing page",
    shotImage: shots3,
    altText: "shots3",
},
{
    title: "Sign up form",
    shotImage: shots4,
    altText: "shots4",
},
];
